@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400..800&display=swap');

.bg-blue {
	/* background-color: #25196A; */
	background-color: #3f3f3f;
}

.clr-yellow {
	color: rgb(251, 255, 0) !important;
}

a {
	text-decoration: none !important;
}

.dropdown {
	width: 100%;
	font-family: 'Helvetica', sans-serif;
	font-weight: 300;
}

@media (min-width: 768px) {
	.dropdown {
		width: 500px;
	}
}

.dropdown__switch:checked+.dropdown__options-filter .dropdown__select {
	transform: scaleY(1);
	opacity: 1;
}

.dropdown__switch:checked+.dropdown__options-filter .dropdown__filter:after {
	transform: rotate(-135deg);
}

.dropdown__options-filter {
	width: 100%;
	cursor: pointer;
}

.dropdown__filter {
	position: relative;
	display: flex;
	padding: 20px;
	color: #595959;
	background-color: #fff;
	border-radius: 18px;
	font-size: 14px;
	transition: 0.3s;
}

.dropdown__filter:focus {
	outline: none;
}

.dropdown__filter:after {
	position: absolute;
	top: 45%;
	right: 20px;
	content: '';
	width: 10px;
	height: 10px;
	border-right: 1px solid #595959;
	border-bottom: 1px solid #595959;
	transform: rotate(45deg) translateX(-45%);
	transition: 0.3s ease-in-out;
}

.dropdown__select {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	margin-top: 5px;
	overflow: hidden;
	transform: scaleY(0);
	transform-origin: top;
	border-radius: 18px;
	opacity: 0;
	transition: 0.2s ease-in-out;
}

.dropdown__select-option {
	padding: 20px;
	background-color: #fff;
	border-bottom: 1px solid #e9ecef;
	transition: 0.3s;
}

.dropdown__select-option:last-of-type {
	border-bottom: 0;
}

.dropdown__select-option:hover {
	background-color: #e9ecef;
}

.bg-red {
	background-color: red !important;
	color: white !important;
}

.clr-blue {
	color: #615f6d !important;
}

.nav-title {
	font-size: 24px !important;
	font-weight: 600 !important;
	color: #25196A !important;
}

.nav-title {
	position: relative;
	display: inline-block;
	padding-bottom: 5px;
	/* Add padding to create space for the border */
	color: inherit;
	/* Keep the original color */
	text-decoration: none;
	/* Remove any default underline */
}
.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0 solid !important;
    border-right: 0 solid transparent !important;
    border-bottom: 0 !important;
    border-left: 0 solid transparent !important;
}
.header-overlay{
	background-color: #000000a3;
	min-height: 400px;
}

.h-400{
	height : 400px;
}

.nav-title::after {
	content: '';
	position: absolute;
	width: 0;
	height: 2px;
	/* Adjust the height of the border */
	bottom: 0;
	left: 0;
	background-color: red;
	/* Set the color of the border */
	transition: width 0.4s ease-out;
	/* Animation duration and easing */
}

.nav-title:hover::after {
	width: 100%;
	/* Expand the border to full width on hover */
}

.nav-title:hover {
	color: red !important;
	/* Change the text color on hover */
}


.overlay {
	background-color: rgba(0, 0, 0, 0.393);
}

.hero-bg {
	background-image: url("./Assests/Image/slide-img.jpg");
	height: 100vh;
	width: 100vw;
	background-size: cover;
}

.ff-lobster {
	font-family: "Poppins", sans-serif;
	font-style: normal;
}

.ff-baloo {
	font-family: "Baloo Bhai 2", sans-serif;
	font-style: normal;
}

/* Header.css */

.gallery-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	gap: 15px;
	padding: 20px;
}

.gallery-item {
	overflow: hidden;
	position: relative;
}

.gallery-item img {
	width: 100%;
	height: auto;
	transition: transform 0.3s ease;
}

.gallery-item:hover img {
	transform: scale(1.1);
}

.key-hover:hover {
	/* font-size: 20px; */
	transform: scale(1.2);
	position: relative;
	left: 6%;
	font-weight: 900;
}

.cursor-pointer {
	cursor: pointer !important;
}

.dropdown-container {
	position: relative;
}

.dropdown {
	display: none;
	position: absolute;
	background-color: white;
	min-width: 160px;
	border-radius: 6px;
	box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
	z-index: 1;
}

.dropdown-item {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.dropdown-item:hover {
	background-color: #f1f1f1;
}

.dropdown-container:hover .dropdown {
	display: block;
}

.bg-service {
	background-color: whitesmoke;
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");

p {
	margin: 0;
	line-height: 1.6;
}

ol {
	list-style: none;
	counter-reset: list;
	padding: 0 1rem;
}

li {
	--stop: calc(100% / var(--length) * var(--i));
	--l: 62%;
	--l2: 88%;
	--h: calc((var(--i) - 1) * (180 / var(--length)));
	--c1: hsl(var(--h), 71%, var(--l));
	--c2: hsl(var(--h), 71%, var(--l2));

	position: relative;
	counter-increment: list;
	max-width: 28rem;
	margin: 2rem auto;
	padding: 2rem 1rem 1rem;
	box-shadow: 0.1rem 0.1rem 1.5rem rgba(0, 0, 0, 0.3);
	border-radius: 0.25rem;
	overflow: hidden;
	background-color: white;
}

li::before {
	content: '';
	display: block;
	width: 100%;
	height: 1rem;
	position: absolute;
	top: 0;
	left: 0;
	background: linear-gradient(to right, var(--c1) var(--stop), var(--c2) var(--stop));
}


@media (min-width: 40em) {
	li {
		margin: 3rem auto;
		padding: 3rem 2rem 2rem;
	}

	h3 {
		font-size: 2.25rem;
		margin: 0 0 2rem;
	}

	h3::before {
		margin-right: 1.5rem;
	}
}